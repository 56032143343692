import "./cards.css";
import bg from "../../bg.jpg";
import Button from "../Button";
import Modal from "../Modal";
import '../../index.css'
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Buttons from '../buttons/Button'

const Card = ({ title, repo, desc, pic, project }) => {
  console.log(project);
  const [modalOpen, setModalOpen] = useState(false);
    const close = () => setModalOpen(false);
    const open = () => setModalOpen(true);
    const handleClick = () => {
      modalOpen ? close() : open();
    }

  return (
    <>
      <div className="card">
        <div className="card__image">
          <img src={project.image ? project.image : bg} alt="hello" />
        </div>
        {project.winner ? 
        <div className="tag">Winner</div>
          : null}
        <div className="card__title">{project.title}</div>
        <div className="card__body">
          <NavLink target="_blank" to={{pathname:"http://www.google.com"}} ><Button text="website" /></NavLink>
          <Button text="description" onClick={handleClick} />
        </div>
      </div>
   {modalOpen && <Modal modalOpen={modalOpen} handleClose={close} text={project.description} title={project.title} />}
    </>
  );
};

export default Card;
