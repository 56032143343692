import React from "react";
import Home from "./Home";
import SocialIcons from "../../components/Icons/SocialIcons";
import "./styles.css";
import Back from '../../components/Background/Back';
import About from "../../components/about/About";

const Homepage = () => {
  return (
    <>
      <SocialIcons />
      <div className="App">
        <Back>
          {/* <div>
            hello
          </div> */}

          {/* <About /> */}
          <Home />
        </Back>
        {/* <Home /> */}
      </div>
    </>
  );
};

export default Homepage;
