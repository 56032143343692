import { useState } from 'react'
import { motion } from 'framer-motion';
import Modal from '../Modal';
import '../../index.css'
import './btn.css';
const Button = ({ onClick, text, style}) => {
   

    return (
        <div>
            <motion.button
                whileHover={{ scale: 1 }}
                whileTap={{ scale: 0.9 }}
                className="save-button"
                onClick={onClick}
                style={style}
                >
                {text}
            </motion.button>
        </div>
    )
}

export default Button;