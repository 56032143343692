import React from "react";
// import "./btn.css";

const Button = (props) => {
  return (
    <>
      <div style={props.style}>
        <button onClick={props.onClick}>{props.text}</button>
      </div>
    </>
  );
};

export default Button;
