import React from 'react'
import Back from '../../components/Background/Back';

const About = () => {
  return (
      <Back>
    <div>index</div>
      </Back>
  )
}

export default About;