import React from "react";
import "./svg.css";

export const Try = ({ color = "#000", width = "1.2pt", fill = "none" }) => {
  return (
    <>
      <svg
        className="css-1wvgl9v"
        style={{ width: "1.5rem" }}
        viewBox="0 0 30 30"
      >
        <path d="M15 30a15 15 0 1115-15 15 15 0 01-15 15zm0-23.77A8.77 8.77 0 1023.77 15 8.77 8.77 0 0015 6.23z"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="-0.8 31.906 27.656"
        className="foo-svg"
      >
        <g transform="matrix(1 0 0 -1 .919 25.856)">
          <g>
            <g stroke={color}>
              <g fill="#111">
                <g strokeWidth=".4pt">
                  <g>
                    <g>
                      <g strokeWidth={width}>
                        <path fill={fill} d="M0 0h28.453L14.226 24.641z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg className="foo-svg" viewBox="0 0 30 30">
        <path d="M14.921 2.27L28.667 25.5 1.175 25.5z"></path>
      </svg>
      <svg className="css-uw67g2" style={{ left: "5%" }} viewBox="0 0 30 30">
        <path d="M28 2v26H2V2h26m.13-2H1.88A1.88 1.88 0 000 1.88v26.25A1.88 1.88 0 001.88 30h26.25A1.87 1.87 0 0030 28.13V1.88A1.88 1.88 0 0028.13 0z"></path>
      </svg>
    </>
  );
};

export const Run = () => {
  return (
    <>
      <svg className="foo-svg css-1fyybsj" viewBox="0 0 30 42">
        <path d="M28.74 20.81H1.26a1.26 1.26 0 01-1-2L14.16.5a1.25 1.25 0 011-.5 1.24 1.24 0 011 .51L29.75 18.8a1.25 1.25 0 01-1 2zM3.81 18.29h22.41L15.16 3.37zM28.74 42H1.26a1.28 1.28 0 01-1.13-.71A1.26 1.26 0 01.26 40l13.9-18.29a1.28 1.28 0 011-.5 1.24 1.24 0 011 .51L29.75 40a1.26 1.26 0 01.12 1.32 1.28 1.28 0 01-1.13.68zM3.81 39.47h22.41L15.16 24.55z"></path>
      </svg>
      <svg className="css-1rrwmzt" viewBox="0 0 30 30">
        <path d="M14.921 2.27L28.667 25.5 1.175 25.5z"></path>
      </svg>
      <svg
        className="css-ihhqwk"
        style={{ width: "2rem", marginLeft: "-80px" }}
        viewBox="0 0 30 30"
      >
        <path d="M15 30a15 15 0 1115-15 15 15 0 01-15 15zm0-23.77A8.77 8.77 0 1023.77 15 8.77 8.77 0 0015 6.23z"></path>
      </svg>
      {/* <svg className="css-uw67g2" style={{ left: "40%" }} viewBox="0 0 30 30">
        <path d="M28 2v26H2V2h26m.13-2H1.88A1.88 1.88 0 000 1.88v26.25A1.88 1.88 0 001.88 30h26.25A1.87 1.87 0 0030 28.13V1.88A1.88 1.88 0 0028.13 0z"></path>
      </svg> */}
    </>
  );
};

export const Foo = () => {
  return (
    <>
      <svg className="css-1wvgl9v" viewBox="0 0 30 30">
        <path d="M15 30a15 15 0 1115-15 15 15 0 01-15 15zm0-23.77A8.77 8.77 0 1023.77 15 8.77 8.77 0 0015 6.23z"></path>
      </svg>
      <svg className="css-ihhqwk" viewBox="0 0 100 100">
        <path d="M60.5 50l34.8-34.8c2.9-2.9 2.9-7.6 0-10.5-2.9-2.9-7.6-2.9-10.5 0L50 39.5 15.2 4.7c-2.9-2.9-7.6-2.9-10.5 0-2.9 2.9-2.9 7.6 0 10.5L39.5 50 4.7 84.8c-2.9 2.9-2.9 7.6 0 10.5 1.4 1.4 3.3 2.2 5.2 2.2s3.8-.7 5.2-2.2L50 60.5l34.8 34.8c1.4 1.4 3.3 2.2 5.2 2.2 1.9 0 3.8-.7 5.2-2.2 2.9-2.9 2.9-7.6 0-10.5L60.5 50z"></path>
      </svg>
      <svg className="css-uw67g2" viewBox="0 0 30 30">
        <path d="M28 2v26H2V2h26m.13-2H1.88A1.88 1.88 0 000 1.88v26.25A1.88 1.88 0 001.88 30h26.25A1.87 1.87 0 0030 28.13V1.88A1.88 1.88 0 0028.13 0z"></path>
      </svg>
      <svg className="css-1dadoe5" viewBox="0 0 30 44.58">
        <path d="M28.74 44.58a1.28 1.28 0 01-1-.51L15.16 27.13l-12.89 17a1.26 1.26 0 11-2-1.53l13.9-18.29a1.34 1.34 0 011-.5 1.24 1.24 0 011 .51l13.58 18.24a1.27 1.27 0 01-1 2zM14.83 20.82a1.28 1.28 0 01-1-.52L.25 2a1.27 1.27 0 012-1.51l12.59 16.96L27.73.5a1.26 1.26 0 012 1.53L15.84 20.32a1.28 1.28 0 01-1.01.5z"></path>
      </svg>
    </>
  );
};

export const Bar = () => {
  return (
    <>
      <svg className="css-1dadoe5" viewBox="0 0 30 44.58">
        <path d="M28.74 44.58a1.28 1.28 0 01-1-.51L15.16 27.13l-12.89 17a1.26 1.26 0 11-2-1.53l13.9-18.29a1.34 1.34 0 011-.5 1.24 1.24 0 011 .51l13.58 18.24a1.27 1.27 0 01-1 2zM14.83 20.82a1.28 1.28 0 01-1-.52L.25 2a1.27 1.27 0 012-1.51l12.59 16.96L27.73.5a1.26 1.26 0 012 1.53L15.84 20.32a1.28 1.28 0 01-1.01.5z"></path>
      </svg>
    </>
  );
};
