import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from './screens/Home/Homepage';
import Projects from './screens/Projects/Projects';
import About from './screens/About';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/projects" exact element={<Projects />} />
        <Route path="/about" exact element={<About />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
