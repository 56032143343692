import React from "react";
import bg from "../../bg.jpg";
import Card from "../../components/cards/Card";
import "./projects.css";
import {projects } from "../../assets/Data/personal";
import Back from "../../components/Background/Back";
import Navbar from "../../components/Navbar";

const Projects = () => {
  return (
    <Back>
<div style={{ width: "100%", alignItems: "center", display: "flex", justifyContent: 'center'}}>
      <Navbar />
      </div>
      <div
        style={{
          textAlign: "center",
          paddingTop: "20px",
        }}
      >
        <h2 className="project-head">Projects</h2>
      </div>

      <div className="project-card">
        {
          Object.keys(projects).map((project, index) =>  <Card project={projects[project]} key={index} /> )
        } 
      </div>
    </Back>
  );
};

export default Projects;
