// import Background from "./Background";
import { motion } from "framer-motion";
import Home from "../../screens/Home/Home";
import "./styles.css";
import { Try, Run, Foo, Bar } from "./Try";

export default function Back({children}) {
  return (
    <div id="wrapper">
      <div className="content">
        {children}
        {/* <Home /> */}
      </div>
      <div className="back">
        <motion.div
          animate={{ y: [0, 100, 0] }}
          transition={{
            duration: 32,
            loop: Infinity
          }}
          className="svg"
        >
          <Try color="salmon" />
          <Try color="salmon" />
        </motion.div>
        <motion.div
          animate={{ y: [100, 500, 100] }}
          transition={{
            duration: 45,
            loop: Infinity
          }}
          className="svg"
        >
          <Try color="salmon" />
          <Run />
        </motion.div>
        <motion.div
          animate={{ y: [800, 100, 800] }}
          transition={{
            duration: 45,
            loop: Infinity
          }}
        >
          <Try color="salmon" />
          <Foo />
          {/* <Run /> */}
        </motion.div>
        <motion.div
          animate={{ y: [-200, 10, -200] }}
          transition={{
            duration: 45,
            loop: Infinity
          }}
        >
          <Try color="salmon" />
          <Bar />
          {/* <Run /> */}
        </motion.div>
        <svg className="css-x9ahbb" viewBox="0 0 30 30">
          <path d="M15 30a15 15 0 1115-15 15 15 0 01-15 15zm0-23.77A8.77 8.77 0 1023.77 15 8.77 8.77 0 0015 6.23z"></path>
        </svg>
      </div>
    </div>
  );
}
