import { Link } from "react-router-dom";
import { useState } from "react";
import Button from "../../components/buttons/Button";
import "./Home.css";
import About from "../../components/about/About";
import Modal from "../../components/Modal";
import { motion } from 'framer-motion';

const TEXTS = ["Student", "Full Stack Developer", "Hacker", "Cool", "Intruiging", "Challenging"];

const Home = () => {
  const [showAbout, setShowAbout] = useState(false);
  const close = () => setShowAbout(false);
  const open = () => setShowAbout(true);
  console.log(showAbout);
  return (
    <>
      <div className="wrapper">
        <div>
          <h2>Hi, I'm Aditya Bhadauriya!</h2>
          <div style={{ padding: "20px 0px" }}>
            <div className="scroller">
              <span>
                {
                  TEXTS.map((text)=>
                    <div key="index">{text}<br/></div>
                 )
                }
              </span>
            </div>
          </div>
        <div style={{ display: "flex", justifyContent: "space-around", padding: '10px', width: '100%'}}>
            <Button text="About" onClick={() => setShowAbout(!showAbout)} />
          <Link to="projects">
            <Button text="Projects" />
          </Link>
        </div>
      </div>
 

        </div>
     {
       showAbout && (

      <Modal handleClose={close} title="About" text=""><About /></Modal>
        )
      }
    </>
  );
};

export default Home;
