import React from 'react'
import { Link } from 'react-router-dom';
import Button from '../buttons/Button';

const Navbar = () => {
  return (
    <Link to="/"><Button text="Close" style={{paddingTop: '1rem', width: 'fit-content', textAlign: 'center'}} /></Link>
  )
}

export default Navbar;