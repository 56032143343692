import React from 'react'
import './about.css'
const About = () => {
  return (
        <div className="about">
          <p>
          I'm a junior pursuing my bachelor's degree in Computer Science and Engineering! I'll be graduating in 2023.
          For the past two years I've been actively participating in hackathons, ctfs and other online events and workshops
          which helped me to explore the many aspects of programming. 
          </p>
          <br/>
         <p>
I've decent knowledge of developing <span>full stack web apps,</span>
          <span>RestApis, using Python, JavaScript, Flask, Django, React, HTML, CSS while having knowledge of Java and C</span> also.
          Currently I'm looking for internship opportunities for summer 2022.
           </p> 
           <br/>
          <div>Beyond my personal and team projects, I study hard at school and work hard to grow.
          
          And besides school and work, I teach secondary and high school students. 
          Also in my free time I like to play chess and dance to sate my competitive side!
          </div>          
        </div>
  )
} 

export default About;