export const projects = {
    Incredibly: {
        "title": "incredibly",
        "description": "A credible tool to security: InCredibly keeps you secure by scanning malicious links and email IDs across the web. Therefore saving you from malicious and untrusted websites and links. In today's world, security is a major concern. On an average, there are about 2200 attacks per day. When you click on a malicious link or a link from an untrusted source, attackers try to exploit the vulnerabilities found in your accounts and systems. Don’t compromise your security, your security is important to us. Real-time scanning detects malware, viruses, abuse, phishing or reputation issues. Use this tool to source check suspicious links and websites.",
        "website": "http://github.com/9aditya9",
        "winner": true,
        "image": "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/578/329/datas/medium.png",
    },
    InstantStudy: {
        "title": "InstantStudy",
        "description": "InstantStudy is a speech to text summarizing tool that makes studying easier for students by summarizing their long lectures! \
        We used React for the frontend and express.js for the backend and flask api which gives the text to trained machine learning model which gives the summary back", 
        "website": "https://github.com/9aditya9/makeuc-backend",
        "winner": true,
        "image": "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/683/476/datas/medium.png",
    },
    BrainHealthDetection: {
        "title": "Brain Health Detection",
        "description": "A Stroke is a serious life threatening condition that effect 790,000 people in the United States every year[1]. Only 1 in 4 of these individual had a stroke before. During a stroke the blood supply to the brain is cut off. \
        Our project predicts the likelihood of a patient or a person of having a stroke on the basis of 10 categories. Once the data are entered and submitted the ML model will return the probability for the event to occour. \
        For this project we have used Numpy, Pandas, TensorFlow, HOTG's Rune, .yml and React.", 
        "website": "https://github.com/9aditya9/HackPrinceton21",
        "winner": true,
        "image": "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/728/718/datas/medium.jpg"
    },
    HarmonyUs: {
        "title": "HarmonyUs",
        "description": "Covid-19 has made interaction and sharing our hardships with others even harder due to social isolation. Those negative thoughts that are constantly clouded over our minds will ultimately lead to a downfall in productivity and overall happiness.\
         We wanted to create a way for people to dump those thoughts and emotions onto somewhere safe, accepting, and supportive, thus the creation of HarmonyUs.\
         We used the React framework, three js, material ui v5, Google cloud NLP api, firebase to build the webapp",
        "website": "https://github.com/9aditya9/makeuc-backend",
        "winner": false,
        "image": "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/844/632/datas/medium.png"
    },
    PeaceAnalyzer: {
        "title": "Peace Analyzer",
        "description": "A webapp to determine if online content is mentally harmful or not by converting a given data into text data with assembly-ai and testing the data through a neural network model.\
        Peace Analyzer works as following:\
        1. User uploads a video or an article to peace analyzer.\
        2.Assembly Ai API convert the audio data into text data.\
        3. Neural Network model will test the text data and output the probability. If the probability is less than 50%, then the content is likely to be unhealthy. If the probability is more than 50%, the it is likely to be healthy.", 
        "website": "https://github.com/9aditya9/makeuc-backend",
        "winner": false,
        "image": "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/738/162/datas/medium.png"
    }
}