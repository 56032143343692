import React from 'react'
import Backdrop from '../Backdrop';
import { motion } from 'framer-motion';
import '../../index.css'
import Button from '../Button';

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 55,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
        transition: {
            duration: 5,
            type: "spring",
        }
    }
}

const Modal = ({ handleClose, text = "Nothing here!", title="title", children }) => {
    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal "
                variants={dropIn}
               
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <h1>{title}</h1>
                <p>{`${text}`}</p>
                {children}
                <Button text="close" onClick={handleClose} style={{marginTop: '1rem'}} />
            </motion.div>
        </Backdrop>
    )
}

export default Modal; 